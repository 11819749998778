.admin-box {
  width: 100%;
  max-width: 500px;
  margin: 51px auto;
}
.remembert {
  margin-left: 25px;
}
.orders-index {
  float: left;
  width: 100%;
  padding: 21px 0px;
}
.admin-titleb {
  width: 100%;
  max-width: 400px;
  margin: 0px 0px 0px auto;
}
.pull-right {
  padding-top: 27px;
}
.admin-title h1 {
  text-align: center;
  padding: 21px 0px 32px;
}
.summary {
  text-align: right;
  padding-bottom: 19px;
}
.admin-title {
  font-size: 14px;
}
.admin-top {
  padding-top: 21px;
}
li.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu {
  top: 37px;
}
.gateways-index.admin-title, .order-step-one-index {
  float: left;
  width: 100%;
  padding: 21px 0px;
}
.alignr {
  text-align: right;
}
.pagination {
  align-items: center;
  justify-content: center;
  font-size: 19px;
}
ul.pagination li {
  background: #007bfe;
  margin: 0px 4px;
  font-size: 17px;
}

ul.pagination li.prev, ul.pagination li.next {
  background: transparent;
  font-size: 24px;
  position: relative;
  top: -2px;
}

ul.pagination li a {
  color: #ffffff;
  padding: 0px 6px;
}

ul.pagination li.prev a, ul.pagination li.next a {
  color: #007bfe;
}

ul.pagination li.active {
  background: #0d2464;
}

.first_step, .second_step, .third_step {
  /* max-width: 530px; */
  width: 100%;
}
.first_step .form-group {
  display: flex;
  flex-wrap: wrap;
}
.first_step .form-group lable {
  width: 60%;
}

.first_step .form-group label {
  width: 18%;
  text-align: right;
}

.first_step .form-group input, .first_step .form-group select {
  width: 36%;
  margin: 0px 1%;
}

.first_step .invalid-feedback {
  width: 23%;
}

.first_step_button.select-button {
  margin-left: 20%;
}
.form-control {
  box-shadow: inset 0px 2px 2px #dddddd;
}
.second_step label.custom-control-label img {
  height: 24px;
  width: auto !important;
}

.second_step .custom-control.custom-radio {
  padding-bottom: 12px;
}

.third_step .form-group {
  display: flex;
  flex-wrap: wrap;
}
.third_step .form-group lable {
  width: 60%;
}

.third_step .form-group label {
  width: 18%;
  text-align: right;
}

.third_step .form-group input, .third_step .form-group select {
  width: 36%;
  margin: 0px 1%;
}
.creditc {
  width: 100%;
}

.third_step .invalid-feedback {
  width: 23%;
  line-height: 1.3em;
  padding: 0px;
  margin: 0px;
}
.stext {
  width: 100%;
  max-width: 535px;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 31px;
}

.third_step .form-group.field-orderform-terms {
  display: block;
  width: 100%;
  max-width: 535px;
  border: 1px #cccccc solid;
  padding: 12px;
  border-radius: 4px;
}

.third_step .form-group.field-orderform-terms label {
  width: auto;
}
table.table.table1.cart td {
  border: 0px;
  padding: 13px !important;
}

table.table.table1.cart thead {
  background: #f7f7f7;
  font-weight: bold;
  font-size: 13px;
}


table.table.table1.cart tbody tr img {
  height: 97px;
  width: auto !important;
}

table.table.table1.cart tbody tr td {
  vertical-align: middle;
}

table.table.table1.cart tbody tr:nth-child(even) {
  background: #f7f7f7;
}
tr.ntotal {
  font-weight: 900;
}
.gateways-update {
  padding-top: 30px;
  float: left;
  width: 100%;
}

.gateways-update h1 {
  text-align: center;
  padding-bottom: 21px;
}
#paypal-button-container {
  width: 100%;
}
.ppbtn {
  max-width: 360px;
  width: 100%;
}
@media (max-width:767px) {
  .admin-top {
    padding-top: 11px;
    padding-bottom: 11px;
}
.navbar-dark .navbar-toggler {
  margin-left: auto;
}
.first_step .form-group label {
  width: 100%;
  text-align: left;
}
.first_step .form-group input, .first_step .form-group select {
  width: 100%;
  margin: 0px;
}
.first_step .invalid-feedback {
  width: 100%;
}
.first_step_button.select-button {
  margin-left: 0px;
}
.select-button {
  padding: 6px 18px !important;
}
table.table.table1.cart tbody tr img {
  height: 40px;
  width: auto !important;
}
.first_step, .second_step, .third_step {
  width: 100%;
  padding: 0px !important;
  margin: 0px;
}
.third_step .form-group label {
  width: 100%;
  text-align: left;
}
.third_step .form-group input, .third_step .form-group select {
  width: 100%;
  margin: 0px;
}
.third_step .invalid-feedback {
  width: 100%;
}
.row.first_step .col-md-12, .row.first_step .col-md-12 .row,
.row.second_step .col-md-12, .row.second_step .col-md-12 .row,
.row.third_step .col-md-12, .row.third_step .row, .row.third_step .col-12 {
  padding-left: 0px !important;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
  
}