.scroll-btn {
  border: 1px solid #cccccc;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    display: inline-block;
    padding: 15px 40px;
    margin-top: 20px;
    padding: 20px 40px 25px 40px;
    letter-spacing: -1px;
}
.scroll-btn img {
  position: relative;
  top: -1px;
  margin-right: 3px;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-lg-2 {
  -webkit-box-flex: 0;
  -ms-flex: 20%;
  flex: 20%;
  max-width: 20%;
}
.col-lg-4 {
  -webkit-box-flex: 0;
  -ms-flex: 33.3333%;
  flex: 33.3333%;
  max-width: 33.3333%;
}
.col-lg-6 {
  -webkit-box-flex: 0;
  -ms-flex: 50%;
  flex: 50%;
  max-width: 50%;
}
.col-lg-7 {
  -webkit-box-flex: 0;
  -ms-flex: 70%;
  flex: 70%;
  max-width: 70%;
}
.col-lg-8 {
  -webkit-box-flex: 0;
  -ms-flex: 80%;
  flex: 80%;
  max-width: 80%;
}
.col-lg-12 {
  -webkit-box-flex: 0;
  -ms-flex: 100%;
  flex: 100%;
  max-width: 100%;
}
.text-right {
  text-align: right;
}
.align-items-center {align-items: center;}
.align-items-bottom {align-items: flex-end;}
.pdlr-none {
  padding-left:0;
  padding-right: 0;
}
.mb-35 {margin-bottom: 35px;}
/*upsell page title*/
.upsell-page-title {
  padding-top: 125px;
  padding-bottom: 195px;
}

/* special limited offer */
.special-offer-section {
  background-image: url('/img/video-bg.jpg');
  background-size: cover;
  background-position: center center;
  padding:0 0 100px 0
}
.special-offer-section .container {
  padding:0;
}
.special-offer-title {
  position: relative;
  background-image: url('/img/beka.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  top: -20px;
  padding: 15px 0 40px 0;
}
.special-offer-title2 img {
  width: 280px;
  margin-top: 14px;
}
.special-offer-title-inner {
  position: relative;
  z-index: 2;
}
.special-offer-title h2, .special-offer-title h4 {
  color:#ffffff;
}
.special-offer-title img {
  margin-bottom: 15px;
}
.special-offer-title h2 {
  margin: 0;
  font-size: 60px;
  font-style: italic;
}
.special-offer-title h4 {
  font-size:24px;
  margin:15px 0 0 0;
}
.special-offer-title2 {
  padding-top: 30px;
}
.special-offer-title2 h3 {
  line-height: 1.2;
}
/* ultra-mvp */
.ultra-mvp-section {
  padding-top:95px;
}
.ultra-mvp-section .container {
  padding:0;
}
.ultra-mvp-left {
  font-size:18px;
  line-height:26px;
  color:#101010;
}
.ultra-mvp-left h1 {
  font-style: italic;
  padding: 0px;
  margin: 0 0 42px 0;
}
.ultra-mvp-left ul {
  margin:0 0 40px 0;
  padding:0 0 0 25px;
  list-style-image: url('/img/checks-sign.png');
}
.ultra-mvp-left ul li {
  margin-bottom: 10px;
}
.ultra-mvp-left-bottom p {
  margin:0;
}
.ultra-mvp-left-bottom strong {
  display: block;
  margin-bottom: 10px;
}
.ultra-bottle img {
  max-width:300px;
}
/* health-duo-section */
.health-duo-section {
  padding:130px 0 0 0;
}
.health-duo-title {
  padding-left:60px;
}
.section-title h1, .health-duo-title h1 {
  font-size:62px;
  font-style: italic;
  line-height: 1.1;
  padding: 0px;
  margin: 0 0 42px 0;
}
/* health-process-section */
.health-process-section {
  padding-top:130px;
}
.health-process-section .container {
  background-color:#ebebeb;
  border:1px solid #cccccc;
  padding:35px 45px 70px 30px;
}
.health-process-title {
  margin-bottom: 74px;
}
.health-process-section h3 {
  font-size:36px;
  line-height:1.2;
  font-style: italic;
  padding: 0px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -2px;
}
.single-process.text-center img {
  height: 57px;
  margin-bottom: 18px;
}
.single-process h5 {
  font-size:18px;
  line-height: 1;
  color:#010101;
}
/* ultra order section */
.ultra-order-section {
  padding: 120px 0 20px 0;
}
.ultra-order-title h1 {
  margin-bottom: 32px;
}
.ultra-order-list ul {
  margin:0;
  padding:0;
  list-style-image: url('/img/checks-sign-2.png');
  list-style-position: inside;
}
.ultra-order-list ul li {
  font-size:32px;
  font-weight: 600;
  line-height: 1;
  color:#101010;
  border:1px solid #cccccc;
  border-radius:5px;
  margin-bottom: 2px;
  padding:10px 80px 18px 80px;
}
.ultra-order-list {
  max-width: 54%;
  margin: 0 auto 55px auto;
}
.orderbtn.orderbtn-large {
  background: url(/img/btn-bg.jpg) repeat-x left top;
    color: #ffffff;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    padding: 9px 0px;
    border-radius: 6px;
    font-size: 30px;
    text-shadow: 0px 1px 2px #000000cc;
    -webkit-transition: all 0.4s ease-in-out;
    -webkit-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;
    background-size: contain;
    font-size: 36px;
    float: none;
    display: inline-block;
    max-width: 370px;
    width: 100%;
}
.order-complete-statement h4 {
  font-size:18px;
  line-height: 1.8;
  color:#7a7a7a;
}

/* responsive */
/* Tablet  :991px. */
@media (max-width: 991px) {
.section-title h1, .health-duo-title h1 {
  font-size: 48px;
}
.health-process-section h3 br {
  display: none;
}
.container, .ultra-mvp-section .container {
  padding: 0 3%;
}
.special-offer-title h2 {
  font-size: 36px;
}
.special-offer-title h4 {
  font-size: 18px;
}
.ultra-order-list ul li {
  padding: 10px 50px 18px 50px;
}
.ultra-order-list {
  max-width: 66%;
}
.scroll-btn {
  padding: 20px 20px 25px 20px;
}
.special-offer-title2 br {
  display: none;
}
.upsell-page-title {
  padding: 125px 0;
}
}
/* Mobile  :767px. */
@media (max-width: 767px) {
.col-lg-6 {
  -ms-flex: 100%;
  flex: 100%;
  max-width: 100%;
}
.col-lg-2 {
  -webkit-box-flex: 0;
  -ms-flex: 20%;
  flex: 50%;
  max-width: 50%;
}
.section-title h1, .health-duo-title h1, .health-process-section h3 {
  font-size: 27px;
}
.section-title h1 br, .health-duo-title h1 br {
  display: none;
}
.upsell-page-title {
  padding: 80px 0;
}
.ultra-mvp-right.text-right {
  text-align: center;
  margin-top:50px;
}
.health-duo-section {
  padding: 50px 0 0 0;
}
.health-duo-title {
  padding-left: 60px;
  text-align: center;
  padding-left: 0;
}
.ultra-order-section {
  padding: 35px 0 20px 0;
}
.health-process-section {
  padding-top: 60px;
}
.special-offer-title h2 {
  font-size: 27px;
}
.special-offer-title2 h3 {
  font-size: 18px;
}
.ultra-order-list {
  max-width: 100%;
}
.ultra-order-list ul li {
  font-size: 18px;
}
}
/* Mobile  :480px. */
@media (max-width: 480px) {

.scroll-btn img:first-child {
  display: none;
}
.ultra-order-list ul li {
  padding: 10px 10px 18px 10px;
}
.orderbtn.orderbtn-large {
  padding: 30px 80px;
  font-size: 20px;
}
.col-lg-2 {
  -webkit-box-flex: 0;
  -ms-flex: 100%;
  flex: 100%;
  max-width: 100%;
}
}