html, body {
  padding: 0px;
  margin: 0px;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 23px;
  color: #202020;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #000000;
}

h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -4px;
  text-transform: uppercase;
  line-height: .9em;
}

h2 {
  font-size: 42px;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 1em;
}

h3 {
  font-size: 25px;
}

img {
  max-width: 100%;
}

.font-italic {
  font-style: italic;
}

.text-center {
  text-align: center;
}
a:hover, a:focus {
  color: inherit;
  text-decoration: none;
}
.container {
  max-width: 1100px;
  margin: 0px auto;
  padding: 0px 3%;
  width: 100%;
}

header {
  float: left;
  width: 100%;
  padding: 10px 0px 0px;
}

.header-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.logo {
  width: 24%;
}

.header-right {
  width: 50%;
  text-align: right;
}

.tollfree {
  font-size: 24px;
  font-weight: 700;
  font-style: italic;
  letter-spacing: -1;
  padding-bottom: 10px;
}

.tollfree a {
  color: #202020;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all .4s ease-out;
}

.tollfree a:hover {
  color: #367DE3;
}

.secure-icons img {
  width: 255px;
}

nav {
  float: left;
  width: 100%;
  background: rgb(53, 122, 208);
  background: linear-gradient(180deg, rgba(53, 122, 208, 1) 0%, rgba(28, 67, 137, 1) 45%, rgba(6, 19, 74, 1) 100%);
  color: #ffffff;
  margin-top: 10px;
}

nav ul {
  padding: 0px;
  margin: 0px;
}

nav li {
  padding: 0px 23px;
  margin: 0px 0px;
  list-style: none;
  float: left;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all .4s ease-out;
}

nav li a {
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  padding: 15px 0px 17px;
  display: inline-block;
}

nav li:hover, nav li.current-menu {
  background: #5685c582;
}

.mobilem {
  display: none;
}

section {
  float: left;
  width: 100%;
  padding: 40px 0px;
}

.page-title h1 {
  font-style: italic;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

section.videot {
  background: url("/img/video-bg.jpg") no-repeat center;
  background-size: cover;
}

.video-bg {
  text-align: center;
  width: 100%;
  margin: 0px auto;
  max-width: 700px;
}

h2.colorb {
  text-align: center;
  color: #367dd5;
  font-style: italic;
  line-height: 1em;
  margin: 50px 0px 0px;
}

section.supplements ul {
  margin: 0px;
  padding: 0px 0px 0px 82px;
  list-style: none;
}

section.supplements li {
  padding: 32px 0px;
  margin: 0px;
  border-bottom: 1px #dddddd solid;
  position: relative;
}

section.supplements li:before {
  background: url(/img/blt.png) no-repeat left top;
  content: "";
  position: absolute;
  width: 90px;
  height: 90px;
  background-size: 100%;
  left: -94px;
  top: 19px;
}

section.supplements li strong {
  color: #367cd5;
  font-size: 17px;
}

.supplement-banner {
  padding: 82px 0px 63px;
}

.supplement-btm h2 {
  text-transform: uppercase;
  text-align: center;
  line-height: 1em;
  max-width: 80%;
  width: 100%;
  margin: 0px auto;
  padding-bottom: 23px;
}

section.supplements p {
  padding: 9px 0px;
}

section.discover {
  background: #f2f2f2;
}

section.discover h2 {
  text-transform: uppercase;
  text-align: center;
  line-height: 1em;
  margin: 16px 0px -5px;
}

section.discover p {
  text-align: center;
}

.blue-text {
  text-align: center;
  color: #367cd5;
  font-weight: 700;
  font-size: 23px;
  padding-bottom: 12px;
}

ul.customerssay {
  padding: 19px 6%;
  margin: 0px;
  list-style: none;
}

ul.customerssay li {
  border-bottom: 1px #dddddd solid;
  padding: 10px 57px;
  font-size: 18px;
}

section.discover h3 {
  text-align: center;
  text-transform: uppercase;
  font-size: 29px;
  letter-spacing: -1px;
  margin: 24px 0px 10px;
}

.bluelink {
  width: 100%;
}

.bluelink ul {
  margin: 0px;
  padding: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.bluelink li {
  padding: 0px 4px;
  margin: 0px 15px;
  color: #367bd5;
  font-weight: 700;
  text-transform: uppercase;
}

.bluelink li:first-child {
  list-style: none;
}

section.advanced h2 {
  text-align: center;
  margin: 21px 0px 8px;
  line-height: 1em;
}

.text-uppercase {
  text-transform: uppercase;
}

.blue-sub-title {
  text-align: center;
  color: #357ad5;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 27px;
  letter-spacing: -1px;
  font-style: italic;
  padding-top: 4px;
}

.phase-out {
  width: 88%;
  padding: 12px 0px;
  margin: 0px auto;
}

.phase-in {
  display: flex;
  flex-wrap: wrap;
  border-top: 4px #3479d4 solid;
  background: #f2f2f2;
  margin: 24px 0px;
  transform: skewX(-20deg);
}

.phase-left {
  width: 18%;
  background: url('/img/phasebg.jpg') no-repeat center top;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.phase-right {
  width: 80%;
  padding: 10px 36px;
  font-size: 15px;
  line-height: 1.3em;
  min-height: 100px;
  display: flex;
  align-items: center;
}

.phase-in span {
  transform: skewX(20deg);
  float: left;
  width: 100%;
}

.formula-in {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 6px #367bd5 solid;
  padding: 0px 3% 0px 0px;
}

.formula-left {
  width: 72%;
  position: relative;
}

.formula-right {
  width: 25%;
}

.formula-left ul {
  margin: 0px;
  padding: 0px 0px 0px 4%;
}

.formula-left li {
  margin: 0px;
  padding: 0px 0px 18px 25px;
  list-style: none;
  background: url('/img/blue-arrow.png') no-repeat left 3px;
  background-size: 12px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -1px;
  font-style: italic;
}

.testi-out {
  padding: 100px 0px 0px;
}

.testi-in {
  box-shadow: 0px 3px 18px 6px #00000024;
  padding: 40px 50px;
  text-align: center;
  line-height: 1.7em;
  font-size: 17px;
  font-style: italic;
  position: relative;
  margin-bottom: 70px;
}

.testi-in h3 {
  padding: 0px;
  margin: 0px 0px 19px 0px;
}

.testi-in:before {
  content: "";
  background: url('/img/quotel.png') no-repeat center;
  position: absolute;
  width: 55px;
  height: 55px;
  background-size: 51px;
  left: 16px;
  top: 10px;
}

.testi-in:after {
  content: "";
  background: url(/img/quoter.png) no-repeat center;
  position: absolute;
  width: 55px;
  height: 55px;
  background-size: 51px;
  right: 16px;
  top: 10px;
}

.deserve {
  padding: 36px 40px 33px;
  border-bottom: 6px #367bd5 solid;
}

.deserve h2 {
  width: 100%;
  margin: 0px auto 7px !important;
  padding-bottom: 13px;
  max-width: 900px;
}

.middle-text {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5em;
  padding-top: 24px;
  letter-spacing: -1px;
}
.middle-text p {
  margin: 12px 0px;
}

.pro-box {
  width: 100%;
  padding: 75px 0px 0px;
}

.pro-box h2 {
  width: 100%;
  margin: 0px auto !important;
  padding-bottom: 43px;
  max-width: 720px;
}

.pro-in {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pro-left {
  width: 50%;
  padding-right: 5%;
}

.pro-right {
  width: 50%;
  padding-left: 5%;
}

.pro-right ul {
  margin: 0px;
  padding: 0px 0px 12px;
}

.pro-right li {
  margin: 0px;
  padding: 0px 0px 20px 25px;
  list-style: none;
  background: url('/img/blue-arrow.png') no-repeat left 3px;
  background-size: 12px;
  font-size: 26px;
  font-weight: 700;
  font-style: italic;
  letter-spacing: -1px;
}

.learn-more a {
  color: #367dd5;
  text-transform: uppercase;
  font-size: 16px;
}

.orderbtn {
  padding-top: 5px;
}

.orderbtn a {
  background: url(/img/btn-bg.jpg) repeat-x left top;
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  padding: 14px 25px;
  float: left;
  border-radius: 6px;
  font-size: 23px;
  text-shadow: 0px 1px 2px #000000cc;
  -webkit-transition: all 0.4s ease-in-out;
  -webkit-transition: all .4s ease-out;
  -o-transition: all .4s ease-out;
  transition: all .4s ease-out;
  margin-right: 13px;
}

.orderbtn a:hover {
  opacity: .9;
}

.learn-more {
  width: 330px;
  text-align: center;
}

.statement-box {
  padding: 91px 17% 0px;
  text-align: center;
  font-size: 12px;
  line-height: 1.4em;
  color: #a4a4a4;
}

.statetement-bg {
  border: 1px #a4a4a4 solid;
  padding: 11px 30px;
  margin-bottom: 22px;
}

.statement-box img {
  width: 250px;
}

footer {
  background: #06172c;
  float: left;
  width: 100%;
  padding: 40px 0px;
  color: #656b72;
}

footer a {
  color: #656b72;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all .4s ease-out;
}

footer a:hover {
  color: #b1b1b1;
}

.footer-in {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.footer-left {
  width: 32%;
  padding-right: 2%;
}

.footer-right {
  width: 63%;
}

.footer-right ul {
  margin: 0px;
    padding: 0px;
    width: 100%;
    text-align: right;
}

.footer-right li {
  padding: 0px 0px 0px 2px;
  margin: 0px 0px 0px 6px;
  text-transform: uppercase;
  font-size: 12px;
  float: none;
  display: inline-block;
  list-style: none;
  position: relative;
}

.footer-right li:first-child:before{
  display: none;
}

.footer-right li a {
  margin-left: 8px;
}
.footer-right  li:before {
  width: 4px;
  height: 4px;
  content: "";
  background: #656b71;
  position: absolute;
  border-radius: 50%;
  top: 10px;
  left: -1px;
}
.footer-toll {
  font-size: 23px;
  font-weight: 700;
  font-style: italic;
  text-align: right;
  padding-top: 7px;
  float: left;
  width: 100%;
  padding-right: 8px;
}

.copyright-in {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 23px;
  font-size: 13px;
}

.copyright-left {
  width: 45%;
  padding-right: 2%;
}

.copyright-right {
  width: 50%;
  text-align: right;
}

.copyright-right a {
  padding: 0px 8px;
}
.pro-type-img img {
  max-height: 240px;
}
.video-player {
  cursor: pointer;
}
td.tdRight.tdRight10, td.tdRight1.tdRight10 {
  text-align: left;
}
table.table.table1.cart td.trText2, td.align-middle.pt-5.pb-5.tdText4, td.align-middle.pt-3.pb-5.tdTextFree {
  text-align: right;
  padding-right: 30px !important;
}
td.tdRight, td.tdRight1 {
  text-align: right;
}
/* FAQ */

.blue-large {
  color: #367dd5;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -2px;
  text-align: center;
  padding: 11px 0px 2px;
}

.text-medium {
  text-align: center;
  font-size: 21px;
  letter-spacing: -1px;
}

.blue-border {
  border-bottom: 5px #367cd5 solid;
  padding-bottom: 14px;
}

section.advanced.btm-pro .pro-box {
  padding-top: 30px;
}

section.faq-box {
  padding: 20px 0px;
}

.faq-out {
  width: 100%;
}

.faq-in {
  width: 100%;
  padding-bottom: 30px;
}

.faq-in h3 {
  padding: 12px 21px 12px 60px;
  margin: 0px;
  text-transform: uppercase;
  background: #367dd5;
  color: #ffffff;
  letter-spacing: -1px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  line-height: 1.1em;
}

.faq-in h3:before {
  content: "\f067";
  position: absolute;
  left: 14px;
  transition: all 0.2s ease-in-out;
  font: normal normal normal 22px/1 FontAwesome;
  top: 14px;
}

.faq-in h3.cfaq:before {
  content: "\f068";
  transition: all 0.2s ease-in-out;
}

.faq-text {
  background: #f2f2f2;
  padding: 41px;
  display: none;
}

/* Guarantee */

.small-top {
  padding-top: 12px;
}

.no-space {
  padding: 0px !important;
  margin: 0px !important;
}

.guarantee-middile {
  width: 100%;
  text-align: center;
  padding-bottom: 50px;
}

.guarantee-middile h2 {
  color: #367dd5;
  text-transform: uppercase;
  font-size: 52px;
  font-style: italic;
  margin: 11px 0px 25px 0px;
}

.guarantee-middile h3 {
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -2px;
  margin-top: 111px;
  margin-bottom: 22px;
}

.formula-left h3 {
  color: #367dd5;
  text-transform: uppercase;
  font-size: 33px;
  letter-spacing: -2px;
  line-height: 1em;
}

.formula-left .orderbtn {
  text-align: center;
  padding-bottom: 21px;
  display: table;
  width: 100%;
  margin: 0px auto;
  padding-top: 10px;
  max-width: 520px;
}

.expectt .formula-left li {
  padding: 0px 0px 14px 25px;
  font-size: 17px;
  text-transform: uppercase;
}

.expectt .formula-right img {
  margin: 0px -72px;
  width: 312px;
  max-width: none;
}

.expect-btm {
  text-align: center;
  padding: 60px 0px 0px;
  line-height: 1.7em;
  font-weight: 700;
  font-style: italic;
  font-size: 18px;
}

section.page-title.faq-title {
  padding-top: 50px;
}

.banner-bg {
  background: url("/img/banner-bg.png") no-repeat right bottom #f3f3f3;
  padding: 30px 30px 0px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 11px #367dd5 solid;
  margin-top: 47px;
}

section.advanced.order-top {
  padding-top: 50px;
}

.banner-bg h2 {
  margin: 0px;
  width: 100%;
  padding-bottom: 16px;
  text-transform: uppercase;
  font-size: 44px;
}

.banner-left {
  width: 70%;
}

.banner-right {
  width: 29%;
  padding-left: 4%;
}

.right-stext {
  font-size: 31px;
  font-weight: 700;
  letter-spacing: -2px;
  font-style: italic;
  color: #367dd5;
  padding: 0px 21px 5px 11px;
}

.left-stext {
  font-size: 31px;
  font-weight: 700;
  letter-spacing: -2px;
  font-style: italic;
  color: #367dd5;
  padding: 0px 21px 11px 49px;
}

.banner-text {
  font-size: 15px;
  line-height: 1.7em;
  padding-bottom: 36px;
  padding-top: 9px;
}

.banner-btn {
  text-align: center;
  width: 100%;
}

.banner-btn a {
  background: #367cd5;
  color: #ffffff;
  text-decoration: none;
  padding: 5px 40px;
  font-weight: 700;
  font-size: 19px;
  letter-spacing: -1px;
  border-radius: 16px 16px 0px 0px;
}

.testi-auth {
  padding: 12px 0px 0px;
  border-top: 1px #dddddd solid;
  margin-top: 24px;
  margin-bottom: -19px;
  color: #367cd5;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
}

.medium-top {
  padding-top: 30px;
}

.large-bottom {
  padding-bottom: 70px;
}

.testimonials-box {
  padding: 0px;
}

section.testimonials-box .testi-out {
  padding-top: 40px;
  margin-bottom: -30px;
}

/* How It Works */

.black-sub-title {
  color: #101010;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -3px;
  text-align: center;
  padding-top: 11px;
}

.orderbtn.text-center a {
  padding: 15px 50px;
  float: none;
  display: inline-block;
  margin-top: 11px;
}
section.supplies {
  padding-top: 40px;
}
.medium-black-text {
  font-size: 21px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  max-width: 490px;
  width: 100%;
  margin: 0px auto;
  padding-top: 19px;
  padding-bottom: 10px;
  color: #202020;
  line-height: 1.2em;
}
.field-orderform-creditcardtype {
  display: none !important;
}
.testi-out.testi-page-out {
  padding: 100px 20px 0px;
}
.creditCardType-box > div .icon {
  width: 70px;
  padding-right: 15px;
  flex-grow: 0;
  flex-shrink: 0;
}
.creditCardType-box {
  padding-left: 19%;
  padding-bottom: 16px;
  display: none;
}

.creditCardType-box .icon {
  float: left;
}
.cc-mastercard .name {
  position: relative;
  top: 4px;
}
.terms h1 {
  line-height: 1em;
  margin: 0px;
  padding-top: 21px;
}
.creditCardType-box > div {
  display: none;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
}
.creditCardType-box > div .icon img {
  max-width: 100%;
}
.terms h2 {    
  font-size: 27px;
  letter-spacing: 0px;
  line-height: 1em;
  padding-top: 40px;
  margin: 0px 0px -8px 0px;
}

section.terms, section.terms p {
  font-size: 15px;
  line-height: 1.6em;
}

section.terms p a, section.terms a {
  color: #367dd5;
  text-decoration: none;
}
h2.while-suplies {
  margin: 0px;
  text-align: center;
  font-weight: 500;
  font-size: 37px;
  text-transform: uppercase;
  color: #367dd5;
  font-weight: 700;
  font-style: italic;
  padding-top: 15px;
  margin-bottom: -6px;
}
section.videot h2 {
  padding: 0px 0px 41px;
  margin: 0px auto;
  text-align: center;
  max-width: 788px;
}

.doorstep {
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: -1px;
  font-weight: 700;
  color: #367dd5;
  line-height: 1em;
}

.pricet {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  padding: 12px 0px;
}

.order-text {
  text-align: center;
  font-size: 19px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 0px 0px;
  color: #202020;
  line-height: 1.4em;
  font-weight: 600;
  font-style: italic;
  letter-spacing: -1px;
}
.order-text p {
  margin: 12px 0px;
}
.arrow-down {
  text-align: center;
  color: #367dd5;
  text-transform: uppercase;
  padding: 0px 0px 30px;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
}
.formtitle {
  font-size: 39px;
  font-weight: 900;
  letter-spacing: -1px;
  text-transform: uppercase;
  line-height: .9em;
  font-style: italic;
  padding: 27px 0px 9px;
}
.arrow-down img {
  width: 127px;
  margin-top: 6px;
  margin-top: 21px;
}

.probe-formula h2 {
  text-align: center;
  margin: 6px;
}

.prostate-relief {
  padding: 40px 3px;
  border-bottom: 4px #357ad5 solid;
}

.prostate-relief h3 {
  text-align: center;
  font-size: 34px;
  text-transform: uppercase;
  line-height: 1.1em;
  letter-spacing: -2px;
}

.prostate-relief p {
  padding: 9px 0px;
}

.prostate-relief strong {
  color: #367dd5;
}

input[type="checkbox"] {
  margin-right: 7px;
}

.video-btm-text {
  text-align: center;
  color: #367dd5;
  font-style: italic;
  line-height: 1em;
  margin: 50px 0px 0px;
  font-size: 32px;
  letter-spacing: -1px;
  font-weight: 700;
}

.about-prostate h2 {
  text-align: center;
  text-transform: uppercase;
  max-width: 860px;
  width: 100%;
  margin: 0px auto 48px;
}

.about-prostate-in ul {
  margin: 35px 17px;
}

.about-prostate-in li {
  padding: 7px 0px 0px 7px;
}

.about-prostate h2.text-lowercase {
  text-transform: none;
}

.about-prostate h3 {
  color: #367dd5;
  margin-bottom: -6px;
  font-size: 19px;
  margin-top: 27px;
}

.about-prostate-in {
  width: 100%;
  padding: 40px 3px;
  border-bottom: 4px #357ad5 solid;
}

.about-prostate {
  padding-top: 0px;
}

.prostate-related {
  background: #f2f2f2;
  padding-bottom: 80px;
}

.prostate-related h2 {
  text-align: center;
  margin-bottom: 33px;
  max-width: 790px;
  width: 100%;
  margin: 20px auto 10px;
}

.prostate-related h3 {
  color: #367dd5;
  margin-bottom: -5px;
  margin-top: 40px;
  display: inline-block;
  font-size: 22px;
}

.prostate-related h4 {
  font-size: 20px;
  margin: 36px 0px -6px;
  display: table;
}

.prostate-related img {
  float: right;
  margin: 30px 0px 30px 30px;
}

.img1 {
  width: 420px;
}

.img2 {
  width: 250px;
  margin: 0px 0px 10px 30px !important;
}

.img3 {
  width: 420px;
}

.prostate-ingrediants h2 {
  text-align: center;
  margin: 20px 0px 5px;
}

.ingrediants-out {
  width: 100%;
  padding-top: 60px;
  border-bottom: 4px #357ad5 solid;
}

.ingrediants-in {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 78px;
}

.ingradinats-left {
  width: 21%;
}

.ingradinats-right {
  width: 77%;
}

.ingradinats-right h3 {
  margin: 0px 0px 12px;
  font-size: 21px;
  color: #367cd5;
}

section.page-title.testimonials-page-title {
  padding-top: 80px;
}

.testi-out.testipage-out {
  padding: 100px 30px 0px;
}

.ingradinats-left img {
  border-radius: 50%;
  border: 6px #367dd5 solid;
  width: 176px;
}

.banner-bg.bg-transparent {
  background: transparent;
  border: 0px;
  margin-top: 0px;
}

.clinical-studies {
  background: #f1f1f1;
  text-align: center;
}

.clinical-studies h2 {
  text-align: center;
  text-transform: uppercase;
}

.clinical-studies-in {
  padding-bottom: 60px;
}

.clinical-studies-img img {
  box-shadow: 1px 3px 30px 0px #0000007d;
}

.clinical-studies-img {
  padding-bottom: 28px;
}

.clinical-studies-link a {
  color: #367dd5;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all .4s ease-out;
}

.clinical-studies-link a:hover {
  color: #000000;
}

/* Order Page */

.pro-box.grey-bg {
  background: url(/img/banner-bg.png) no-repeat right bottom #f3f3f3;
  padding: 24px 30px 0px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 11px #367dd5 solid;
  margin-top: 16px;
}

.pro-box.grey-bg .pro-left {
  width: 51%;
  padding-right: 5%;
}

.pro-box.grey-bg .pro-right {
  width: 41%;
  padding-left: 5%;
}

.pro-box.grey-bg .pro-in {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
}

section.order-form h2 {
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 900;
  font-style: italic;
  border-bottom: 1px #d7d7d7 solid;
  width: 100%;
  padding-bottom: 6px;
  max-width: 539px;
}

.pro-type {
  width: 100%;
}

.pro-type-out {
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.pro-type-in {
  width: 33.33%;
  background: #f1f1f1;
  padding: 21px 0px;
}

.pro-type-in.recommanded {
  background: #10253f;
}

.month-supply {
  background: url('/img/supply-img.jpg') no-repeat center;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 900;
  font-style: italic;
  font-size: 23px;
  letter-spacing: -1px;
  padding: 8px 25px;
  border-radius: 80px;
  width: max-content;
  margin: 0px auto 24px;
  text-shadow: 1px 1px 2px #000000;
}

.shippingt {
  color: #367dd5;
  display: flex;
  text-transform: uppercase;
  font-weight: 900;
  font-style: italic;
  font-size: 25px;
  letter-spacing: -2px;
  text-align: center;
  width: max-content;
  margin: 6px auto;
}

.shippingt svg {
  width: 37px;
  color: #367dd5;
  fill: #367dd5;
  padding-right: 6px;
}

.pro-content {
  text-align: center;
  padding: 0px 20px;
}

.pro-price {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
  font-style: italic;
  letter-spacing: -1px;
  font-size: 41px;
  padding: 4px 0px 8px;
}

.pro-price span {
  font-size: 18px;
}

.total-price {
  color: #e50404;
  font-size: 12px;
}

.savet {
  text-transform: uppercase;
  margin: -4px;
}

.list-price {
  font-size: 12px;
  text-transform: uppercase;
  color: #a2a2a2;
}

.select-btn a {
  background: url(/img/btn-bg.jpg) repeat-x left top;
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 54px;
  display: inline-block;
  border-radius: 6px;
  font-size: 20px;
  text-shadow: 0px 1px 2px #000000cc;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all .4s ease-out;
}

.select-btn {
  padding-top: 3px;
}

.select-btn a:hover {
  opacity: .9;
}

.recommanded .pro-price {
  color: #ffffff;
}

.recommanded .pro-price sup {
  position: absolute;
  color: #db0b0d;
  font-weight: 700;
  font-size: 19px;
  top: -5px;
  left: 20px;
  transform: rotate(-23deg);
}

.recommanded .savet {
  color: #ffffff;
}

.payment-type {
  padding: 37px 0px;
}

.payment-type-title {
  padding-bottom: 19px;
}

.payment-type-out {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.payment-type-in {
  background: #f5f5f4;
  padding: 3px;
  border: 3px #000000 solid;
  margin-right: 15px;
  border-radius: 7px;
  text-align: center;
  font-size: 12px;
}

.payment-type-in img {
  width: 157px;
}

.payment-type-lable {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-type-in.type-current {
  border: 3px #1bae03 solid;
}

.form-out {
  max-width: 550px;
}

.form-in {
  display: flex;
  font-size: 15px;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 15px;
}

.form-in label {
  width: 30%;
  text-align: right;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -1px;
  padding: 0px 15px;
}

.form-in input[type="text"], .form-in input[type="email"] {
  width: 68%;
  padding: 7px;
  border: 1px #cccbcb solid;
  box-shadow: inset 0px 2px 2px #dddddd;
  border-radius: 4px;
  height: 37px;
}

.form-box {
  width: 100%;
  padding-bottom: 27px;
}

select {
  padding: 7px;
  border: 1px #cccbcb solid;
  box-shadow: inset 0px 2px 2px #dddddd;
  border-radius: 4px;
  height: 35px;
  background: #f3f3f3;
  margin-right: 16px;
}

.form-in strong {
  font-size: 17px;
  font-style: italic;
  letter-spacing: -1px;
  font-weight: 900;
}

.form-btm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.form-btm-in {
  width: 72%;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  padding-bottom: 3px;
  align-items: center;
}

input[type="submit"] {
  background: #25c500;
  color: #ffffff;
  border: 0px;
  text-align: center;
  max-width: 320px;
  width: 100%;
  font-size: 21px;
  padding: 7px;
  margin: 7px 0px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all .4s ease-out;
  cursor: pointer;
}

input[type="submit"]:hover {
  background: #000000;
}

.form-btm-in img {
  width: 268px;
}

.order-form {
  padding-bottom: 0px;
  margin-bottom: -60px;
}

.form-in span {
  font-size: 11px;
  color: #888888;
}

@media(max-width:980px) {
  .header-right {
      width: 70%;
  }
  nav li {
      padding: 0px 0px;
      font-size: 14px;
  }
  nav li a {
      padding: 14px 0px 16px;
  }
  .supplement-btm h2 {
      max-width: 100%;
      width: 100%;
  }
  .prolabel {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
}
  ul.customerssay {
      padding: 19px 0%;
  }
  ul.customerssay li {
      padding: 10px 0px;
  }
  section.discover h3 {
      font-size: 27px;
      margin: 24px 0px 31px;
  }
  .bluelink li {
      padding: 0px 10px;
      margin: 0px 4px;
      list-style: none;
  }
  .phase-out {
      width: 97%;
  }
  .formula-left li {
      padding: 0px 0px 10px 18px;
      font-size: 16px;
  }
  .testi-in {
      padding: 40px 40px;
  }
  .deserve {
      padding: 36px 0px 33px;
  }
  .deserve h2 {
      width: 100%;
  }
  .pro-box {
      width: 100%;
      padding: 45px 0px 0px;
  }
  .pro-box h2 {
      width: 100%;
  }
  .pro-left {
      padding-right: 0%;
  }
  .pro-right {
      padding-left: 3%;
  }
  .statement-box {
      padding: 71px 10% 0px;
  }
  .footer-left {
      width: 100%;
      padding-right: 0%;
      text-align: center;
      padding-bottom: 16px;
  }
  .formula-left .orderbtn {
      width: max-content;
  }
  .footer-left img {
      width: 191px;
  }
  .footer-right {
      width: 100%;
  }
  .footer-right ul {
      float: none;
      width: max-content;
      margin: 0px auto;
  }
  .footer-toll {
      text-align: center;
      padding-top: 19px;
  }
  .copyright-left {
      width: 100%;
      padding-right: 0%;
      text-align: center;
      order: 2;
      padding-top: 6px;
  }
  .copyright-right {
      width: 100%;
      text-align: center;
      padding-bottom: 7px;
  }
  .faq-in h3 {
      font-size: 21px;
  }
  .guarantee-middile h3 {
      margin-top: 71px;
      line-height: 1em;
  }
  .formula-left h3 {
      font-size: 22px;
  }
  .formula-left {
      position: relative;
  }
  .banner-bg.bg-transparent {
      padding-top: 0px;
  }
  .statement-box.medium-top {
      padding-top: 30px;
  }
  .banner-bg h2 {
      font-size: 37px;
  }
  .right-stext, .left-stext {
      font-size: 25px;
  }
  .recommanded .pro-price sup {
      top: -8px;
      left: -16px;
  }
  .month-supply {
    font-size: 20px;
    width: 80%;
    margin: 0px 0px 23px;
  }
  h2.while-suplies {
      font-size: 33px;
  }
  .doorstep, .pricet {
      font-size: 27px;
  }
  sup {
    top: -.8em;
    font-size: 17px;
}
  .pro-price {
    font-size: 32px;
  }
  .shippingt svg {
    width: 29px;
  }

}

@media(max-width:767px) {
  h1 {
      font-size: 25px;
      letter-spacing: -2px;
  }
  h2 {
      font-size: 25px;
  }
  header>.container {
      border-bottom: 3px #3679cf solid;
      padding-bottom: 10px;
  }
  nav {
      display: none;
      margin-top: 0px;
  }
  .logo {
      width: 50%;
  }
  .header-right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  }
  .mobilem {
      display: block;
      float: right;
      width: 35px;
      height: 3px;
      background: #071951;
      position: relative;
  }
  .doorstep, .pricet {
    display: none;
  }
  .mobilem:before, .mobilem:after {
      position: absolute;
      height: 3px;
      width: 35px;
      content: "";
      left: 0px;
      background: #061a51;
      transition: all 0.2s ease-in-out;
  }
  .mobilem.mobilecm {
      background: transparent;
  }
  .mobilem.mobilecm:before {
      top: 0;
      transform: rotate(-45deg);
  }
  .mobilem.mobilecm:after {
      bottom: 0;
      transform: rotate(45deg);
  }
  .mobilem:before {
      top: -8px;
  }
  .mobilem:after {
      bottom: -8px;
  }
  .tollfree, .secure-icons {
      display: none;
  }
  .pro-type-img {
    width: 50%;
    order: 3;
}
.pro-content {
  text-align: left;
  padding: 0px 20px;
  width: 50%;
}
.shippingt {
  font-size: 16px;
  text-align: left;
  margin: 0px 0px 4px;
}
.pro-price {
  font-size: 21px;
  text-align: left;
  padding-top: 0px;
  padding-bottom: 0px;
}
.total-price, .savet, .list-price {
  text-align: left;
  margin: -2px 0px 0px;
}
sup {
  top: -0.9em;
  font-size: 12px;
}
.pro-price span {
  font-size: 15px;
}
.select-button {
  font-size: 17px !important;
}
.shippingt svg {
  width: 27px;
}
.pro-type-img {
  width: 50%;
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
  display: flex;
  align-items: center;
}
  nav ul {
      padding: 0px;
      margin: 14px 0px;
      float: left;
      width: 100%;
  }
  nav li {
      width: 100%;
  }
  nav li a {
      padding: 11px 11px 10px;
      width: 100%;
  }
  .video-bg {
      width: 100%;
  }
  h2.colorb {
      margin: 12px 0px 0px;
  }
  section.supplements li:before {
      width: 50px;
      height: 50px;
      left: -58px;
      top: 26px;
  }
  section.supplements ul {
      padding: 0px 0px 0px 60px;
  }
  .supplement-banner {
      padding: 30px 0px 40px;
  }
  .supplement-btm h2 {
      padding-bottom: 10px;
  }
  ul.customerssay {
      padding: 10px 0%;
  }
  .bluelink ul {
      flex-wrap: wrap;
  }
  .bluelink li {
      padding: 0px 10px 12px;
      width: 100%;
  }
  section.advanced h2 {
      margin: 0px 0px 21px;
  }
  .phase-out {
      width: 100%;
  }
  .phase-in {
      transform: skewX(0deg);
  }
  .phase-in span {
      transform: skewX(0deg);
  }
  .phase-left {
      width: 100%;
      padding: 16px;
  }
  .phase-right {
      width: 100%;
      padding: 21px;
  }
  .formula-in {
      border-bottom: 0px #367bd5 solid;
      padding: 0px;
  }
  .formula-left {
      width: 100%;
      order: 2;
      padding-top: 21px;
  }
  .formula-right {
      width: 100%;
      border-bottom: 6px #367bd5 solid;
      padding: 0px 3%;
  }
  .testi-out {
      padding: 50px 0px 0px;
  }
  .testi-in:before {
      background: url(/img/quotel.png) no-repeat center;
      width: 25px;
      height: 25px;
      background-size: 25px;
      left: 10px;
      top: 5px;
  }
  .testi-in:after {
      background: url(/img/quoter.png) no-repeat center;
      width: 25px;
      height: 25px;
      background-size: 25px;
      right: 10px;
      top: 5px;
  }
  .testi-in {
      padding: 38px 20px;
  }
  .testi-in h3 {
      font-size: 20px;
      line-height: 1.2em;
  }
  .testi-text {
      font-size: 16px;
      line-height: 1.5em;
  }
  .deserve {
      padding: 13px 0px 33px;
  }
  .blue-text {
      font-size: 16px;
  }
  .middle-text {
      font-size: 18px;
      line-height: 1.5em;
      padding-top: 11px;
      text-align: left;
  }
  .pro-left {
      width: 100%;
  }
  .pro-right {
      padding-left: 0%;
      width: 100%;
      padding: 12px 0px 0px;
  }
  .statement-box {
      padding: 29px 0% 0px;
  }
  .statetement-bg {
      padding: 11px 15px;
  }
  .footer-right ul {
      width: 100%;
  }
  .footer-right ul {
      float: left;
      width: 100%;
      margin: 5px auto;
      display: table;
      text-align: center;
  }
  .footer-right li:before {
    display: none;
  }
  .footer-right li {
      padding: 2px 3px;
      float: none;
      display: inline-block;
  }
  .blue-large {
      font-size: 21px;
  }
  .text-medium {
      font-size: 19px;
  }
  .faq-in h3 {
      font-size: 19px;
  }
  .faq-text {
      padding: 21px;
  }
  section.faq-box {
      padding: 10px 0px;
  }
  section.advanced.btm-pro .pro-box {
      padding-top: 0px;
  }
  .guarantee-middile h2 {
      font-size: 25px;
  }
  .guarantee-middile h3 {
      margin-top: 60px;
  }
  .formula-left h3 {
      margin-top: 0px;
  }
  .expect-btm {
      padding: 30px 0px 0px;
  }
  .banner-bg h2 {
      font-size: 30px;
      text-align: center;
  }
  .banner-bg {
      padding: 30px 15px 0px 15px;
  }
  .banner-left {
      width: 100%;
      order: 2;
  }
  .banner-right {
      width: 100%;
      padding-left: 0%;
      text-align: center;
  }
  .banner-right img {
      width: 170px;
      margin: 0px auto;
  }
  .banner-btn {
      order: 3;
  }
  .right-stext {
      padding: 0px;
      text-align: center;
  }
  .left-stext {
      padding: 8px 0px 0px;
      text-align: center;
  }
  .banner-btn a {
      padding: 5px 9px;
      font-size: 13px;
      border-radius: 6px 6px 0px 0px;
  }
  .statement-box.medium-top {
      padding-top: 0px;
  }
  .large-bottom {
      padding-bottom: 40px;
  }
  section.testimonials-box .testi-out {
      padding-top: 20px;
  }
  .pro-right .orderbtn a {
    padding: 13px 10px;
    width: 46%;
    text-align: center;
    margin-bottom: 11px;
    margin-right: 5px;
    font-size: 16px;
    margin-left: 5px;
}
  .pro-right .learn-more {
      width: 100%;
  }
  .black-sub-title {
      font-size: 23px;
      line-height: 1em;
  }
  .orderbtn.text-center a {
      padding: 15px 25px;
      font-size: 24px;
  }
  .prostate-relief h3 {
    font-size: 19px;
    line-height: 1.4em;
    letter-spacing: -1px;
    text-transform: none;
}
  .prostate-relief {
    padding: 30px 3px;
  }
  .about-prostate-in {
      padding: 20px 3px 30px;
  }
  .about-prostate {
      padding-bottom: 20px;
  }
  .prostate-related h3 {
      margin-top: 16px;
  }
  .ingradinats-left {
      width: 100%;
      padding-bottom: 22px;
      text-align: center;
  }
  .ingradinats-right {
      width: 100%;
  }
  .clinical-studies-link a {
      font-size: 13px;
  }
  .pro-type-in {
    width: 100%;
    background: #f1f1f1;
    padding: 30px 0px;
    margin-bottom: 32px;
    max-width: 370px;
    margin: 0px auto 32px;
}
  .payment-type-out {
      justify-content: center;
  }
  .payment-type-in {
      margin-bottom: 11px;
      width: 250px;
  }
  .form-in label {
      width: 100%;
      text-align: left;
      padding: 0px 0px;
  }
  .form-in input[type="text"], .form-in input[type="email"] {
      width: 100%;
      padding: 7px;
  }
  select {
      width: 100%;
      margin-right: 0px;
  }
  select.cardc {
      width: 45%;
      margin: 0px 2% 0px;
  }
  .form-btm-in {
      width: 100%;
      justify-content: flex-start;
      font-size: 11px;
      line-height: 1.4em;
  }
  .pro-box.grey-bg .pro-left {
      width: 100%;
      padding-right: 0%;
  }
  .pro-box.grey-bg .pro-right {
      width: 100%;
      padding-left: 0%;
      padding-top: 18px;
  }
  .pro-right li {
      font-size: 21px;
  }
  .order-form {
      padding-top: 0px;
  }
  input[type="submit"] {
      max-width: none;
      width: 100%;
  }
  .video-btm-text {
      font-size: 24px;
  }
  h2.while-suplies {
      font-size: 25px;
  }
  .about-prostate-in ul {
      margin: 29px 5px;
  }
  .about-prostate h2 {
      margin: 0px auto 30px;
      font-size: 19px;
      letter-spacing: -1px;
  }
  section.page-title.testimonials-page-title {
      padding-top: 53px;
  }
  .testi-out.testi-page-out {
      padding: 100px 0px 0px;
  }
  .doorstep {
      font-size: 22px;
      display: none;
  }
  .pricet {
      font-size: 25px;
      display: none;
  }
  .probe-formula h2 {
      margin: 6px 0px;
  }
  .guarantee-middile h3 {
      font-size: 25px;
  }
  .expect-btm {
      line-height: 1.4em;
      font-size: 16px;
  }
  .orderbtn a {
      padding: 15px 40px;
      font-size: 25px;
  }
  .deserve h2 {
      width: 100%;
      font-size: 23px;
      line-height: 1.2em !important;
  }
  .order-text {
    padding: 0px 0px 0px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2em;
  }
  .medium-black-text {
      font-size: 15px;
      padding-bottom: 12px;
  }
  section.supplies {
      padding-top: 60px;
  }
  .arrow-down {
      font-size: 17px;
  }
  .pro-box.grey-bg {
      padding: 24px 10px 0px 10px;
  }
}
@media (max-width:400px){
  .img2 {
      width: 100%;
  }
}
